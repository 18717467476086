import React, { useState, useEffect } from "react"

import Gallery from "./Gallery"
import { Loading } from "src/components/common"
import { Section, Description, Title, Wrapper } from "../components"
import { useDispatch } from "react-redux"
import { fetchImages } from "src/api/rest/instagram"
import { fetchCarousel } from "src/redux/carousel"
import { getCarousel } from "src/api/rest/carousel"
const INSTAGRAM_USERNAME = "pracowniasushiwola"
const TOTAL_NUMBER_OF_IMAGES = 4

function About() {
    const [images, setImages] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        const fetchInstagramImages = async () => {
            setIsLoading(true)

            try {
                // const { data: accountData } = await fetchUserData(INSTAGRAM_USERNAME)
                // const images = extractInstagramAccountImages(accountData)
                // const slicedImages = images.slice(0, TOTAL_NUMBER_OF_IMAGES)
                const fetchedImages = await fetchImages()
                setImages(fetchedImages)
            } catch (error) {
                console.error(error)
            }

            setIsLoading(false)
        }
        fetchInstagramImages()
    }, [])

    // function extractInstagramAccountImages(accountData) {
    //   console.log(accountData)
    //   const postedImages =
    //     accountData.graphql.user.edge_owner_to_timeline_media.edges

    //   const imagesURL = postedImages.map(element => ({
    //     id: element.node.shortcode,
    //     url: element.node.display_url,
    //     totalLikes: element.node.edge_media_preview_like.count,
    //     totalComments: element.node.edge_media_to_comment.count,
    //   }))

    //   return imagesURL
    // }

    return (
        <Section>
            <Wrapper id={"about"}>
                <Title> Krótko o nas </Title>
                <Description>
                    Tak, to my od rolki gratis i zestawu Warsaw. Działamy na
                    stołecznym rynku roku ponad dekadę. Zaczynając od małego
                    lokalu przy ulicy Ciołka na Woli z każdym rokiem stawiamy
                    krok do przodu poprawiając jakość nie tylko naszych
                    produktów,ale również obsługi oraz lokali. Wszystko
                    wykonujemy w duchu japońskiej filozofii kaizen polegającej
                    na nieustannym doskonaleniu całej naszej organizacji. Dołącz
                    do nas i rozsmakuj się w sushi. Smacznego!
                </Description>

                {/* {isLoading ? <Loading /> : <Gallery images={images} />} */}
            </Wrapper>
        </Section>
    )
}

export default About
